import { CheckboxProps } from "presentation/model/CheckBoxprops"

interface CheckboxPropsList {
    option: CheckboxProps,
    checkboxList?: CheckboxProps[]
}

export const ManChargeHdrCriteriaDirectoryAllCheckboxOption: CheckboxProps = {key: 'all', name: 'All'}

export const ManChargeHdrCriteriaDirectoryCheckboxOption: CheckboxProps[] = [
    {key: 'refNo', name: 'Ref No.'},
    {key: 'coVslVoy', name: 'Co/Vsl/Voy'},
    {key: 'opsDate', name: 'Ops Date'},
    {key: 'createdDate', name: 'Created Date'},
    {key: 'billToCompList', name: 'Bill to Company'},
    {key: 'stateList', name: 'State'},
    {key: 'chargeTypeList', name: 'Charge Type'},
    {key: 'invCreditNoteNo', name: 'Invoice/Credit Note No.'},
    {key: 'cntrNo', name: 'Container No.'},
]

export const ManChargeHdrCriteriaDirectoryVoyageCheckboxOptionList: CheckboxPropsList = {
    option: {key: 'voyage', name: 'VOYAGE'},
    checkboxList: ManChargeHdrCriteriaDirectoryCheckboxOption,
}

export const ManChargeHdrCriteriaDirectoryCheckboxOptionList: CheckboxPropsList[] = [
    ManChargeHdrCriteriaDirectoryVoyageCheckboxOptionList,
]