import { AxiosError } from "axios";
import { SearchPreferenceEntity } from "domain/entity/Common/SearchPreferenceEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import authAxiosInstance from "../axios/authAxiosInstanc";
import { SearchPreferenceRepository } from "./SearchPreferenceRepo";

export const SearchPreferenceRepoImpl = (): SearchPreferenceRepository<any, any> => {
    const url = `/v1/searchpreference`;

    const getAllSearchPreferences = async (): Promise<SearchPreferenceEntity<any, any>[]> => {
        return await axiosGetData(authAxiosInstance, `${url}`, [])
            .then(res => {
                let newPreference:SearchPreferenceEntity<any, any>[] = [];
                if(res.data) {
                    if (_.isArray(res.data)) {
                        newPreference = res.data.map((preference:SearchPreferenceEntity<any, any>) => ({
                            ...preference,
                            searchCriteria: JSON.parse(preference.searchCriteria),
                            criteriaDirectory: JSON.parse(preference.criteriaDirectory),
                        }))
                    } else {
                        res.data.searchCriteria = JSON.parse(res.data.searchCriteria);
                        res.data.criteriaDirectory = JSON.parse(res.data.criteriaDirectory);
                        newPreference.push(res.data);
                    }
                }
                const data = newPreference;
                return _.sortBy(data, ['key'])
            }).catch(error => {
                return []
            })
    }

    /**
     * Get all the search preferences by type
     * @param type 
     * @returns 
     */
    const getSearchPreferencesByEmailAndType = async (email: string, type: string): Promise<SearchPreferenceEntity<any, any>[]> => {
        return await axiosGetData(authAxiosInstance, `${url}/email/type/${email}/${type}`, [])
            .then(res => {
                let newPreference:SearchPreferenceEntity<any, any>[] = [];
                if(res.data) {
                    if (_.isArray(res.data)) {
                        newPreference = res.data.map((preference:SearchPreferenceEntity<any, any>) => ({
                            ...preference,
                            searchCriteria: JSON.parse(preference.searchCriteria),
                            criteriaDirectory: JSON.parse(preference.criteriaDirectory),
                        }))
                    } else {
                        res.data.searchCriteria = JSON.parse(res.data.searchCriteria);
                        res.data.criteriaDirectory = JSON.parse(res.data.criteriaDirectory);
                        newPreference.push(res.data);
                    }
                }
                const data = newPreference;
                return _.sortBy(data, ['key'])
            }).catch(error => {
                return []
            })
    }

    // const getSearchPreferenceByKey = async (key: string): Promise<SearchPreferenceEntity<any, any>> => {
    //     const result = await axiosGetData(authAxiosInstance, `${url}/${key}`, [])
    //         .then(res => {
    //             if(res.data) {
    //                 if (_.isArray(res.data)) {
    //                     res.data.map((preference:SearchPreferenceEntity<any, any>) => ({
    //                         ...preference,
    //                         searchCriteria: JSON.parse(preference.searchCriteria),
    //                         criteriaDirectory: JSON.parse(preference.criteriaDirectory),
    //                     }))
    //                 } else {
    //                     res.data.searchCriteria = JSON.parse(res.data.searchCriteria);
    //                     res.data.criteriaDirectory = JSON.parse(res.data.criteriaDirectory);
    //                 }
    //             }
    //             return res.data
    //         }).catch(error => {
    //             return null
    //         })
    //     return result
    // }

    const createNewSearchPreference = async (newData: SearchPreferenceEntity<any, any>): Promise<SearchPreferenceEntity<any, any>> => {
        if (newData) {
            newData.searchCriteria = JSON.stringify(newData.searchCriteria);
            newData.criteriaDirectory = JSON.stringify(newData.criteriaDirectory);
        }
        const result = await axiosPostData(authAxiosInstance, `${url}`, newData)
            .then(res => {
                return res.data
            }).catch((error: AxiosError) => {
                let errorMessage = "Update Search Preference Failure"
                if (error && error.response && error.response.data && typeof (error.response.data) === "string") {
                    if (error.response.data.includes("already exist")) {
                        errorMessage = `"Search Preference ${newData.cntrSize}" already exists.`
                    }
                }
                throw new Error(errorMessage)
            })
        return result
    }

    const updateSearchPreference = async (updatedData: SearchPreferenceEntity<any, any>): Promise<SearchPreferenceEntity<any, any>> => {
        if (updatedData) {
            updatedData.searchCriteria = JSON.stringify(updatedData.searchCriteria);
            updatedData.criteriaDirectory = JSON.stringify(updatedData.criteriaDirectory);
        }
        const result = await axiosPutData(authAxiosInstance, `${url}`, updatedData)
            .then(res => {
                return res.data
            }).catch(error => {
                return null
            })
        return result
    }

    const deleteSearchPreferenceByKey = async (key: string) => {
        return await axiosDeleteData(authAxiosInstance, `${url}`, key).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    return {
        getAllSearchPreferences: getAllSearchPreferences,
        getSearchPreferencesByEmailAndType: getSearchPreferencesByEmailAndType,
        // getSearchPreferenceByKey: getSearchPreferenceByKey,
        createNewSearchPreference: createNewSearchPreference,
        updateSearchPreference: updateSearchPreference,
        deleteSearchPreferenceByKey: deleteSearchPreferenceByKey
    }
}