import { EMPTY_MANUAL_CHARGE_HEADER_MAINTENANCE_MODEL } from 'presentation/model/ManualChargeMaintenance/ManualChargeHeaderMaintenanceModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: ManualChargeHeaderMaintenanceProvider,
    useTracked: useManualChargeHeaderMaintenanceTracked
} = createContainer(() => useState(EMPTY_MANUAL_CHARGE_HEADER_MAINTENANCE_MODEL), {concurrentMode: true});
export { ManualChargeHeaderMaintenanceProvider, useManualChargeHeaderMaintenanceTracked };

