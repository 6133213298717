import { useManualChargeDetailVM } from "presentation/hook/ManualCharge/useManualChargeDetailVM";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { useEffect } from "react";
import { DialogModal, HPHButton, HPHInputTextarea, IconButton } from "veronica-ui-component/dist/component/core";



export const ViewAttachmentModal: React.FC = () => {
    const [ manChgDtlState ] = useManualChargeDetailTracked();
    const manChgDtlVM = useManualChargeDetailVM();

    useEffect(()=>{
        manChgDtlVM.onAttaModalInit(manChgDtlState.viewState.currentSelectedData);
    },[manChgDtlState.viewState.currentSelectedData, manChgDtlVM])
    
    const onCloseClick = () => {
        manChgDtlVM.onViewAttaClose();
    }

    return <DialogModal
        appendTo='self'
        showButton={false}
        visible={manChgDtlState.viewState.isShowAttachmentModal}
        positions="center"
        resizable={false} 
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">Message</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={onCloseClick} tooltipDisable={true} />
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content">
                <HPHInputTextarea
                    label={'Container'}
                    disabled={true}
                    width="300px"
                    rows={3}               
                    resizable={false}
                    value={manChgDtlState.viewState.attachmentMsg??''} />
            </div>
        }
        className="im-charge-data-search-confirm-modal"
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton label={'Close'} size={'Small'} theme={'Primary'} onClick={onCloseClick} />
            </div>
        }
    />;
};
