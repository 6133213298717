export interface ManualChargeDetailCntrEntity {
    id: number | null,
    versionIdentifier: {
        version?: string
    },
	cntrNo: string | null,
	cntrSize: string | null,
	cntrType: string | null,
	cntrStatus: string | null,
	overLength: number | null,
	overHeight: number | null,
	overWidth: number | null,
	imcoCode: string | null,
	modalityOfArrival: string | null,
	modalityOfDeparture: string | null,
	inTmlDatetime: Date | null,
	outTmlDatetime: Date | null,
	qty: number | null,
	cntrVisitId: string | null,
	specialInd: string | null,
	specialHandlingInd: string | null,
	spCodeList: string | null,
	legSpHandlingCode: string | null,
	legSpHandlingInd: string | null,
	inConsortiumCode: string | null,
	inVesselCode: string | null,
	inVoyageCode: string | null,
	inVesselName: string | null,
	outConsortiumCode: string | null,
	outVesselCode: string | null,
	outVoyageCode: string | null,
	outVesselName: string | null,
	inHandlingTerminal: string | null,
	outHandlingTerminal: string | null,

    [key: string]: string | boolean | null | Object | number | undefined
}

export const EMPTY_MANUAL_CHARGE_DETAIL_CNTR_ENTITY : ManualChargeDetailCntrEntity = {
    id: null,
    versionIdentifier: {
        version: undefined
    },
    cntrNo: null,
    cntrSize: null,
    cntrType: null,
    cntrStatus: null,
    overLength: null,
    overHeight: null,
    overWidth: null,
    imcoCode: null,
    modalityOfArrival: null,
    modalityOfDeparture: null,
    inTmlDatetime: null,
    outTmlDatetime: null,
    qty: null,
    cntrVisitId: null,
    specialInd: null,
    specialHandlingInd: null,
    spCodeList: null,
    legSpHandlingCode: null,
    legSpHandlingInd: null,
    inConsortiumCode: null,
    inVesselCode: null,
    inVoyageCode: null,
    inVesselName: null,
    outConsortiumCode: null,
    outVesselCode: null,
    outVoyageCode: null,
    outVesselName: null,
    inHandlingTerminal: null,
    outHandlingTerminal: null
}