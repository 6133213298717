import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useRef } from "react";
import { HPHButton } from "veronica-ui-component/dist/component/core";

interface FileUploadProps {
    handleUpload?: (e:any) => void,
    disabled?: boolean,
    label?:string,
    size?:'Standard' | 'Small',
    theme?:'Primary' | 'Secondary' | 'Alert',
    accept?:string
}

const FileUpload:React.FC<FileUploadProps> = (props) => {
    const uploadRef = useRef<any>();
  
    const handleFileChange = (event:any) => {
        if (!event || !event.target.files) return;
        
        if (props.handleUpload) {
            props.handleUpload(event);
        }
    };

    const handleClick = () => {
        uploadRef.current?.click();
    }
    
    return (  
        <div>
            <input ref={uploadRef} type="file" accept={props.accept} style={{ display: 'none' }} onChange={handleFileChange} />                     
            <HPHButton label={props.label? props.label : WorkspaceConstant.Common.BUTTON_IMPORT} 
                size={props.size ? props.size : "Standard"} 
                theme={props.theme ? props.theme : "Secondary"} 
                disabled={props.disabled} onClick={handleClick} />
        </div>  
    );  
};  
  
export default FileUpload;