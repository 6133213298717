import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { SearchPreferenceRepoImpl } from "domain/repository/Common/SearchPreferenceRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { ManualChargeHeaderRepoImpl } from "domain/repository/ManualCharge/ManualChargeHeaderRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { useManualChargeHeaderMaintenanceTracked } from "presentation/store/ManualCharge/ManualChargeHeaderMaintenanceProvider";
import { ManualChargeHeaderMaintenanceVM } from "presentation/viewModel/ManualCharge/ManualChargeHeaderMaintenanceVM";
import { useMemo } from "react";

export const useManualChargeHeaderMaintenanceVM = () => {
    const [, setManualChargeHeaderMaintenanceState] = useManualChargeHeaderMaintenanceTracked();
    const manualChargeHeaderMaintenanceVM = useMemo(() =>
        ManualChargeHeaderMaintenanceVM({
            dispatch: [setManualChargeHeaderMaintenanceState],
            chargeTypeRepo: ChargeTypeRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            searchPreferenceRepo: SearchPreferenceRepoImpl(),
            manualChargeHeaderRepo: ManualChargeHeaderRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
        }), [setManualChargeHeaderMaintenanceState])

    return manualChargeHeaderMaintenanceVM
}