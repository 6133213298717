import { calculateStateColor, defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithColorAndCircle } from "presentation/view/components/TableWrapper/TableCellRender";
import { ChargeDetailConstant } from "../ChargeDetailMaintenance/ChargeDetailConstant";
import { ChargeConstant } from "../ChargeHeader/ChargeConstant";

let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_MAN_CHARGE_CNTR_COL_DEF: any[] = [    
    {
        headerName: ChargeConstant.Charge.CNTR_NO,
        field: 'cntrNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        filter: false,
        width: 200,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: ChargeConstant.Charge.SIZE,
        field: 'cntrSize',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 90,
    },
    {
        headerName: ChargeConstant.Charge.TYPE,
        field: 'cntrType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 90,
    },
    {
        headerName: ChargeConstant.InboundContainerSummary.STATUS,
        field: 'cntrStatus',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 90,
    },
    {
        headerName: ChargeConstant.Charge.OVERLENGTH,
        field: 'overLength',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: ChargeConstant.Charge.OVERHEIGHT,
        field: 'overHeight',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: ChargeConstant.Charge.OVERWIDTH,
        field: 'overWidth',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: ChargeDetailConstant.Charge.IMCO_CODE,
        field: 'imcoCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: ChargeDetailConstant.Charge.MODALITY_OF_ARRIVAL,
        field: 'modalityOfArrival',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: '250px'
    },
    {
        headerName: ChargeDetailConstant.Charge.MODALITY_OF_DEPARTURE,
        field: 'modalityOfDeparture',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: '250px'
    },
    {
        headerName: ChargeConstant.Charge.INTMLDATETIME,
        field: 'inTmlDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: '250px',
        dataType : "dateTime",
    },
    {
        headerName: ChargeConstant.Charge.OUTTMLDATETIME,
        field: 'outTmlDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: '250px',
        dataType : "dateTime",
    },
    {
        headerName: ChargeDetailConstant.Charge.OT_DAYS,
        field: 'qty',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: ChargeDetailConstant.Charge.SPECIAL_IND,
        field: 'specialInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: ChargeDetailConstant.Charge.SPECIAL_HANDLING_CODE,
        field: 'spCodeList',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: ChargeDetailConstant.Charge.LEG_SPECIAL_HANDLING_IND,
        field: 'specialHandlingInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: ChargeDetailConstant.Charge.LEG_SPECIAL_HANDLING_CODE,
        field: 'legSpHandlingCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: ChargeDetailConstant.Charge.LEG_SPECIAL_HANDLING_IND,
        field: 'legSpHandlingInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};
    cellRenderers['hdrState'] = cellRenderWithColorAndCircle;

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};
    externalFnctions['hdrState'] = calculateStateColor;
    
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}