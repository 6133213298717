import { SelectionChangedEvent } from "ag-grid-community";
import { ManualChargeDetailEntity } from "domain/entity/ManualCharge/ManualChargeDetailEntity";
import { INITIAL_MAN_CHARGE_DTL_COL_DEF, transferRowData } from "presentation/constant/ManualCharge/ManChargeDtlColumnDefinition";
import { useManualChargeDetailVM } from "presentation/hook/ManualCharge/useManualChargeDetailVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable, Loader } from "veronica-ui-component/dist/component/core";

const ManualChargeDetailTablePanel:React.FC = () => {
    const [ manualChargeDetailState ] = useManualChargeDetailTracked();
    const manChgDtlVM = useManualChargeDetailVM();
    const {isRead,isShowDetailInfo} = manualChargeDetailState.viewState;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const {allowCreate} = anainfoState;
    let gridRef: any = useRef();

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_MAN_CHARGE_DTL_COL_DEF.slice());
            
        gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        manChgDtlVM.updateSelectedManualChargeDetails(selectedRows);
      }, [manChgDtlVM])

    const handleRowClick = useCallback((manualChargeDetail: ManualChargeDetailEntity) => {
        manChgDtlVM.onRowClick(manualChargeDetail);
    }, [manChgDtlVM])

    const handleDelete = useCallback(async(manualChargeDetail: ManualChargeDetailEntity) => {
    }, [])

    const handleAddClick = useCallback(async() => {
        manChgDtlVM.onAdd();      
        // manChgDtlVM.onInitDefaultValue(true);
    },[manChgDtlVM])
    
    const memoManualChargeDetailTable = useMemo(() => {
        return <HPHTable
            id='ManualChargeDetail-table'
            columns={INITIAL_MAN_CHARGE_DTL_COL_DEF.slice()}
            // headerLabel={MAN_CHG_DTL_CONS.MAN_CHG_DTL}
            //onRowDoubleClick={(e: any, manualChargeDetail: ManualChargeDetailEntity) => handleRowDoubleClick(manualChargeDetail)}
            onRowClick={(e: any) => handleRowClick(e.data)}
            data={transferRowData(manualChargeDetailState.manualChargeDetails) || []}
            showPaginator={false}
            editable={false}
            showAddIcon={allowCreate && !isRead && !isShowDetailInfo}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"multiple"}
            onAddClick={handleAddClick}
            onSelectionChanged={handleSelectionChange}
            onDelete={(deleteManualChargeDetail: ManualChargeDetailEntity) => handleDelete(deleteManualChargeDetail)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)"
            ref={gridRef} />;
    }, [manualChargeDetailState.manualChargeDetails, isRead, isShowDetailInfo, handleAddClick, handleSelectionChange, handleRowClick, handleDelete,allowCreate])


    if (manualChargeDetailState.isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>{ <TableWrapper>{memoManualChargeDetailTable}</TableWrapper>}</>;
}

export default memo(ManualChargeDetailTablePanel);