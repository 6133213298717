import { VesselVoyageMasterRepoImpl } from "domain/repository/ChargeDetailMaintenance/VesselVoyageMasterRepoImpl";
import { ManualChargeDetailRepoImpl } from "domain/repository/ManualCharge/ManualChargeDetailRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { ManualChargeDetailCntrVM } from "presentation/viewModel/ManualCharge/ManualChargeDetailCntrVM";
import { useMemo } from "react";

export const useManualChargeDetailCntrVM = () => {
    const [, setManualChargeDetailState] = useManualChargeDetailTracked();
    const manualChargeDetailCntrVM = useMemo(() =>
        ManualChargeDetailCntrVM({
            dispatch: [setManualChargeDetailState],
            manualChargeDetailRepo: ManualChargeDetailRepoImpl(),
            vesselVoyageMasterRepo: VesselVoyageMasterRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            masterDataRepo: MasterDataRepoImpl()
        }), [setManualChargeDetailState])

    return manualChargeDetailCntrVM
}