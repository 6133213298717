
import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { ManualChargeConstant } from "presentation/constant/ManualCharge/ManualChargeConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useManualChargeDetailVM } from "presentation/hook/ManualCharge/useManualChargeDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import FileUpload from "presentation/view/components/FileUpload";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const ManualChargeDetailTitleBar:React.FC = () => {
    const ADJ_TYPE_CONST = ChargeConstantCollection.adjType;
    const [ manChgDtlState ] = useManualChargeDetailTracked();
    const manChgDtlVM = useManualChargeDetailVM();
    const messageBarVM = useMessageBarVM();
    
    const [isLoading, setIsLoading] = useState(false);

    const currentRows = manChgDtlState.selectedManualChargeDetailRows;
    const selectedRowsCount = currentRows.length;
    const { isRead } = manChgDtlState.viewState;

    const manHdr = manChgDtlState.currentManualChargeHeader;
    const tableData = manChgDtlState.manualChargeDetails;

    const [anainfoState] = useANAInfoTracked();
    const {allowUpdate,allowDelete} = anainfoState;
    const [ isShowDelete, setIsShowDelete ] = useState<boolean>(false);

    let applyBtnEnabled = false;
    let uploadBtnEnabled = false;
    let adjBtnEnabled = false;
    let delBtnEnabled = false;
    let viewBtnEnabled = false;

    if(manHdr.hdrState !== ChargeConstantCollection.hdrState.NIL){
        applyBtnEnabled = false;
        uploadBtnEnabled = false;
        delBtnEnabled = false;
    }else{
        applyBtnEnabled = true;
        if (selectedRowsCount <= 0) {
            uploadBtnEnabled = false;
            delBtnEnabled = false;
            viewBtnEnabled = false;
        } else {
            uploadBtnEnabled = true;
            delBtnEnabled = true;
            viewBtnEnabled = true;
        }
    }

    if(tableData && tableData.length > 0 && selectedRowsCount > 0){
        const adjType = tableData[0].adjType;
        if(adjType !== ADJ_TYPE_CONST.NORMAL){
            applyBtnEnabled = false;
            uploadBtnEnabled = false;
            delBtnEnabled = false;
        }

        if(adjType === ADJ_TYPE_CONST.CANCEL_REBILL){
            applyBtnEnabled = true;
            uploadBtnEnabled = true;
        }else if(adjType === ADJ_TYPE_CONST.ADJUSTMENT){            
            applyBtnEnabled = true;
            uploadBtnEnabled = true;
        }else if(adjType === ADJ_TYPE_CONST.CANCEL){            
            applyBtnEnabled = true;
        }else if(adjType === ADJ_TYPE_CONST.CANCEL){            
            delBtnEnabled = false;
        }
    }

    if(manHdr.hdrState === ChargeConstantCollection.hdrState.INV && selectedRowsCount > 0) {
        adjBtnEnabled = true;
    }
   

    const handleApplyClick = () => {
        if(tableData.length > 0){
            setIsLoading(true);
            manChgDtlVM.onApply(manHdr,manChgDtlState.manualChargeDetails).then(()=>{
                manHdr.id && manChgDtlVM.onSearch(manHdr.id).then(()=>{
                    setIsLoading(false);
                }).catch((error) => {                    
                    setIsLoading(false)
                })
            }).catch((error)=>{
                setIsLoading(false)
                messageBarVM.showError(error.message)
            })
        }else{
            messageBarVM.showWarining('No details under this header,Please input 1 detail at least.')
        }        
    }

    const handleUploadClick = (event:any) => {
        if(selectedRowsCount !== 1){
            messageBarVM.showWarining('Please select a record(s) to upload.');
            return ;
        } 

        const files = event.target?.files;
        const file = files[0];

        if(!file){
            return;
        }
        if (file.type !== 'text/plain') {
            messageBarVM.showWarining('Please upload a .txt file');
            return;
        }       
        
        manChgDtlVM.uploadAttachment(currentRows[0], file).then((data) => {
            if (data && data["uploadAttachmentFail"]) {
                messageBarVM.showError(data["uploadAttachmentFail"]);
            } else {
                messageBarVM.showSuccess("The record(s) is uploaded successfully.");
            }
        })        
    }

    const handleAdjustmentClick = () => {
        if(selectedRowsCount <= 0){
            messageBarVM.showWarining('Please select a record(s) to delete.');
            return ;
        }
        manChgDtlVM.handleAdjustmentButtonClick();
    }

    
    const handleDelete = () => {
        if(selectedRowsCount <= 0){
            messageBarVM.showWarining('Please select a record(s) to delete.');
            return ;
        }

        if(tableData.length === 1){
            messageBarVM.showWarining('There is only one manual charge detail, it is not allowed to delete.');
            return ;            
        }

        if(tableData.length === selectedRowsCount){
            messageBarVM.showWarining('Cannot delete all manual charge detail.')
            return ;            
        }

        // show confirm
        setIsShowDelete(false);
        setIsLoading(true);
        manChgDtlVM.onDelete(currentRows).then(()=>{
            manHdr.id && manChgDtlVM.onSearch(manHdr.id).then(()=>{
                setIsLoading(false);
            }).catch((error) => {                    
                setIsLoading(false)
            })
        }).catch((error)=>{
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })

    }

    const handleDeleteClick = useCallback(() => {
        setIsShowDelete(true);
    }, []);    

    const handleCancel = () => {
        setIsShowDelete(false);
    }

    const handleViewClick = () => {
        if(selectedRowsCount !== 1){
            messageBarVM.showWarining('Please select a record.')
            return;
        }
        manChgDtlVM.onSelectedSingleChargeDetail(currentRows[0]);
        manChgDtlVM.onViewAttaOpen();
    }

    return <Sidebarheader style={{width: '100%'}}>
       <HeaderWithBackButton callback={(manChgDtlVM.onCloseScreen)} isShowBackButton={manChgDtlState.isShowCloseIcon}>
            <Breadcrumb>
                <HPHBreadcrumb breadcrumbData={[{title: ChargeConstant.Charge.CHARGE},{ title: ManualChargeConstant.Header.MANUAL_CHARGE }]} onCurrentClick={()=>{}}></HPHBreadcrumb>
            </Breadcrumb>
        </HeaderWithBackButton>
        <StyledAction className="tm-animated-wrapper">
            {
                <>
                    {/* <IconButton fileName='Icon-add' tooltipDisable={false} toolTipText='Add' size="medium" disabled={!addBtnEnabled} onClick={handleAddClick}/> */}
                    {/* <IconButton fileName='Icon-pen' tooltipDisable={false} toolTipText='Edit' size="medium" disabled={!editBtnEnabled} onClick={handleEditClick}/> */}           
                    {(allowUpdate) && /*<HPHButton label={WorkspaceConstant.Common.BUTTON_UPLOAD} size={'Small'} theme={'Secondary'} disabled={!uploadBtnEnabled} onClick={handleUploadClick} />*/ 
                    <FileUpload label={WorkspaceConstant.Common.BUTTON_UPLOAD} disabled={!uploadBtnEnabled || !isRead} accept=".txt" size={"Small"} theme={"Secondary"} handleUpload={handleUploadClick}/>}
                    <HPHButton label={WorkspaceConstant.Common.BUTTON_VIEW} size={'Small'} theme={'Secondary'} disabled={!viewBtnEnabled || !isRead} onClick={handleViewClick} />
                    {(allowUpdate) && <><div className="add-seperator"/>
                    <HPHButton label={WorkspaceConstant.Common.BUTTON_ADJUSTMENT} size={'Small'} theme={'Secondary'} disabled={!adjBtnEnabled} onClick={handleAdjustmentClick} /></>}
                    {(allowDelete) && <HPHButton label={WorkspaceConstant.Common.BUTTON_DELETE} size={'Small'} theme={'Secondary'} disabled={!delBtnEnabled || !isRead} onClick={handleDeleteClick} />}
                    {(allowUpdate) && <HPHButton label={WorkspaceConstant.Common.BUTTON_UPDATEALL} size={'Small'} theme={'Primary'} disabled={!applyBtnEnabled || !isRead} onClick={handleApplyClick} />}
                </>
            }            
            {/*!(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH)) && 
             <IconButton fileName="Icon-cross" toolTipText="Close" tooltipDisable={false} size="medium" onClick={handleClose} />*/}

            {isShowDelete && <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${ManualChargeConstant.Detail.MAN_CHG_DTL}`} 
                contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
                visible={isShowDelete}
                onCancelClick={handleCancel}
                onDeleteClick={handleDelete}
            />}
        </StyledAction>
       
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
    </Sidebarheader>
}

export default memo(ManualChargeDetailTitleBar);