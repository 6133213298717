import { SearchModeEntity } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { EMPTY_MANUAL_CHARGE_HEADER_ENTITY, ManualChargeHeaderEntity } from "domain/entity/ManualCharge/ManualChargeHeaderEntity";
import { ManualChargeHeaderSearchCriteriaEntity } from "domain/entity/ManualCharge/ManualChargeHeaderSearchCriteriaEntity";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { SearchPreferenceRepository } from "domain/repository/Common/SearchPreferenceRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { ManualChargeHeaderRepository } from "domain/repository/ManualCharge/ManualChargeHeaderRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import _ from "lodash";
import { ManChargeHdrCriteriaDirectoryAllCheckboxOption, ManChargeHdrCriteriaDirectoryCheckboxOptionList } from "presentation/constant/ManualCharge/ManChargeHdrCriteriaDirectoryCheckboxOption";
import { ManChargeHdrSearchCriteriaCheckboxRelatedField } from "presentation/constant/ManualCharge/ManChargeHdrSearchCriteriaCheckboxRelatedField";
import { EMPTY_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA, ManualChargeHeaderSearchCriteria } from "presentation/constant/ManualCharge/ManualChargeHeaderSearchCriteria";
import { DEFAULT_ENABLED_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA, ManualChargeHeaderEnabledSearchCriteria } from "presentation/constant/ManualCharge/ManualHeaderEnabledSearchCriteria";
import { DropdownProps } from "presentation/model/DropdownProps";
import { ManualChargeHeaderMaintenanceModel } from "presentation/model/ManualChargeMaintenance/ManualChargeHeaderMaintenanceModel";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { GroupCheckboxList } from "veronica-ui-component/dist/component/core";

interface ManualChargeHeaderMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ManualChargeHeaderMaintenanceModel>> | ((value: SetStateAction<ManualChargeHeaderMaintenanceModel>) => void),
    ]
    chargeTypeRepo: ChargeTypeRepository,
    companyRepo: CompanyRepository,
    searchPreferenceRepo: SearchPreferenceRepository<ManualChargeHeaderSearchCriteria,ManualChargeHeaderEnabledSearchCriteria>,
    manualChargeHeaderRepo: ManualChargeHeaderRepository
    masterDataRepo: MasterDataRepository
}

export const ManualChargeHeaderMaintenanceVM = ({ dispatch,chargeTypeRepo,  companyRepo, searchPreferenceRepo, manualChargeHeaderRepo,masterDataRepo}: ManualChargeHeaderMaintenanceVMProps) => {
    const [manChargeHdrDispatch] = dispatch;


    const onCriteriaDirectoryDropdown = (e: any) => {
        manChargeHdrDispatch(prevState => {
            prevState.checkboxRef[e?.value]?.scrollIntoView()
            return {
                ...prevState,
                highlightedCheckboxKey: e?.value ?? e
            }
        })
    }

    const onCloseScreen = () => {
        document.dispatchEvent(new CustomEvent('closeManualChargeHeaderMaintenance'))
    }

    const onShowLoading = () => {
        manChargeHdrDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        manChargeHdrDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedManualChargeHeaders = (rows:any[]) => {
        manChargeHdrDispatch(prevState => {
            return {
                ...prevState,
                selectedManualChargeHeaderRows: rows                       
            }
        })
    }

    const updateCheckboxRef = (checkboxRef: { [key: string]: HTMLElement | null }) => {
        manChargeHdrDispatch(prevState => ({
            ...prevState,
            checkboxRef: checkboxRef
        }))
    }

    const updateCheckboxValue = (checked: boolean, key: string) => {
        manChargeHdrDispatch(prevState => {
            if (key === ManChargeHdrCriteriaDirectoryAllCheckboxOption.key) {
                ManChargeHdrCriteriaDirectoryCheckboxOptionList.forEach(item => {
                    item.checkboxList?.forEach(item => {
                        prevState.checkboxValue[item.key] = checked
                    })
                })
            }
            ManChargeHdrCriteriaDirectoryCheckboxOptionList.find(item => item.option.key === key)?.checkboxList?.forEach(item => {
                prevState.checkboxValue[item.key] = checked
            })
            prevState.checkboxValue[key] = checked
            return {
                ...prevState,
                highlightedCheckboxKey: null
            }
        })
    }

    const onSaveCheckboxValue = () => {
        manChargeHdrDispatch(prevState => {
            const searchCriteriaFieldName = Object.keys(EMPTY_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA).concat(Object.keys(ManChargeHdrSearchCriteriaCheckboxRelatedField))
            const disabledCriteria = Object.keys(prevState.checkboxValue).filter((item) => !prevState.checkboxValue[item] && searchCriteriaFieldName.includes(item))

            const resetSearchCriteria = disabledCriteria.reduce((obj, fieldName) => {
                //By default, we use disabled field name to retrieve default value from EMPTY_INVENTORY_SEARCH_CRITERIA,
                //If related field found, we use related field to retrieve default value.
                let resetSearchCriteriaPerField = { [fieldName]: EMPTY_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA[fieldName] }
                if (ManChargeHdrSearchCriteriaCheckboxRelatedField[fieldName]) {
                    resetSearchCriteriaPerField = ManChargeHdrSearchCriteriaCheckboxRelatedField[fieldName].reduce((objPerField, fieldNamePerField) => {
                        return {
                            ...objPerField,
                            [fieldNamePerField]: EMPTY_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA[fieldNamePerField],
                        }
                    }, {})
                }
                return {
                    ...obj,
                    ...resetSearchCriteriaPerField
                };
            }, {});

            return {
                ...prevState,
                searchCriteria: { ...prevState.searchCriteria, ...resetSearchCriteria },
                searchCounter: prevState.searchCounter !== 0 ? prevState.searchCounter + 1 : 0,
                enabledSearchCriteria: prevState.checkboxValue,
                isFilterSearchCriteria: true,
                isEditSearchCriteria: true,
            }
        })
    }

    const onResetCheckboxValue = () => {
        manChargeHdrDispatch(prevState => {
            return {
                ...prevState,
                checkboxValue: { ...DEFAULT_ENABLED_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA },
            }
        })
    }

    const onRemoveSearchCriteria = (fieldName: string, isSearch: boolean = true) => {
        //By default, we use disabled field name to retrieve default value from EMPTY_INVENTORY_SEARCH_CRITERIA,
        //If related field found, we use related field to retrieve default value.
        let resetSearchCriteriaPerField = { [fieldName]: EMPTY_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA[fieldName] }
        if (ManChargeHdrSearchCriteriaCheckboxRelatedField[fieldName]) {
            resetSearchCriteriaPerField = ManChargeHdrSearchCriteriaCheckboxRelatedField[fieldName].reduce((objPerField, fieldNamePerField) => {
                return {
                    ...objPerField,
                    [fieldNamePerField]: EMPTY_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA[fieldNamePerField],
                }
            }, {})
        }

        manChargeHdrDispatch(prevState => {
            return {
                ...prevState,
                searchCounter: isSearch ? prevState.searchCounter + 1 : prevState.searchCounter,
                searchCriteria: { ...prevState.searchCriteria, ...resetSearchCriteriaPerField }
            }
        })
    }

    const onRemoveAllSearchCriteria = () => {
        manChargeHdrDispatch(prevState => {
            prevState.currentTableRef?.current?.api.setServerSideDatasource({
                getRows: (params: any) => {
                    params.success({
                        rowData: [],
                        rowCount: 0
                    })
                },
            })
            //customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_HEADER_EDIT_CRITERIA_BACK, { isEdit: false });
            return {
                ...prevState, searchCounter: 0, searchCriteria: { ...EMPTY_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA }
            }
        })
    }

    // Open search criteria panel not preview panel
    const onEditSearchCriteria = () => {
        // DomUtils.addWithSearchClass();
        manChargeHdrDispatch(prevState => {
            // if (prevState.searchCounter === 0) DomUtils.addOnlySearchClass();
            // else DomUtils.addWithSearchClass();
            return { ...prevState, isShowRightPanel: true, isFilterSearchCriteria: true, isEditSearchCriteria: true, }
        });
    }

    // Open directory criteria panel not search criteria preview panel
    const onSwitchSearchCriteriaMode = () => {
        // DomUtils.addWithSearchClass();
        manChargeHdrDispatch(prevState => {
            // if (prevState.searchCounter === 0) DomUtils.addOnlySearchClass();
            // else DomUtils.addWithSearchClass();

            return {
                ...prevState, 
                isShowRightPanel: !prevState.isShowRightPanel, 
                isFilterSearchCriteria: false, 
                isEditSearchCriteria: false, 
                checkboxValue: prevState.enabledSearchCriteria, 
                highlightedCheckboxKey: null,
            };
        });
    }

    const onSearch = async (searchCriteria: ManualChargeHeaderSearchCriteria, searchModeEntity: SearchModeEntity) => {
        // DomUtils.addWithSearchClass();

        const groupCriteriaList: ManualChargeHeaderSearchCriteriaEntity[] = searchModeEntity.groupKeys.map((item, index) => ({
            key: searchModeEntity.rowGroupCols[index], value: item
        }));

        manChargeHdrDispatch(prevState => {                
            return { 
                ...prevState, 
                searchCounter: prevState.searchCounter + 1, 
                manualChargeHeaders: [],
                selectedManualChargeHeaderRows: [],
            };
        });

        return await manualChargeHeaderRepo.getManualChargeHeader({
            manualChargeSearchCriteria: searchCriteria,
            nonOpsSearchCriteria: null,
            startRow: searchModeEntity.startRow,
            endRow: searchModeEntity.endRow,
            searchSort: searchModeEntity.searchSort,
            currentGroup: searchModeEntity.currentGroup,
            groupCriteria: groupCriteriaList,
        }).then((data) => {
            manChargeHdrDispatch(prevState => {                
                return { 
                    ...prevState, 
                    searchCounter: prevState.searchCounter + 1, 
                    manualChargeHeaders: data,
                    searchCriteria: {...searchCriteria},
                    currentManualChargeHeader:null ,
                    selectedManualChargeHeaderRows: [],
                    isAllowAutoSearch: false,
                };
            });
        }).catch((error)=>{
        }) 
        
    }

    const onSearchById = async (id:number) => {
        return await manualChargeHeaderRepo.getManualChargeHeaderById(id).then((data) => {
            return data;
        }).catch((error)=>{
            return null;
        }) 
        
    }

    const loadDropdownOption = async() => {
        await chargeTypeRepo.getChargeTypeByRole('USER').then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOptions:DropdownProps[] = [];
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOptions.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOptions.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                });

                manChargeHdrDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOptions,
                    }
                }))
            }
        )

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                manChargeHdrDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billToCompDropdownOptions: [
                            ...companyCodeDropdownOptions],
                    }
                }))
            }
        );
    }
    

    const onCoVslVoyChange = (inputData: { co?: string, vsl?: string, voy?: string }, fieldName: { co: string, vsl: string, voy: string }) => {
        manChargeHdrDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName.co]: inputData?.co?.toUpperCase().replace(/\s+/g, ''),
                [fieldName.vsl]: inputData?.vsl?.toUpperCase().replace(/\s+/g, ''),
                [fieldName.voy]: inputData?.voy?.toUpperCase().replace(/\s+/g, ''),
            }
        }))
    }

    const onDropdownChange = (e: any, fieldName: string) => {
        manChargeHdrDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e?.value ?? (_.isEmpty(e) ? 
                    (fieldName === "subChgTypeList" ? [] : '') : e),
            }
        }))
    }

    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        manChargeHdrDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        manChargeHdrDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    };

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        manChargeHdrDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: checked,
            }
        }))
    }

    const onMultipleCheckboxChange = (e: (GroupCheckboxList | undefined)[], fieldNames: string[]) => {
        const value = fieldNames.reduce((obj, fieldName) => {
            return {
                ...obj,
                [fieldName]: !!e.find((checkboxProp) => checkboxProp?.key === fieldName),
            };
        }, {});
        manChargeHdrDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                ...value
            }
        }))
    }

    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {        
        manChargeHdrDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e.target.value.toUpperCase().replace(/\s+/g, ''),
            }
        }))
    };

    
    const onGroupCheckboxChange = (e: (GroupCheckboxList | undefined)[], searchCriteria:ManualChargeHeaderSearchCriteria, fieldName:string) => {
        let selectedValue:string[] = [];
            if (e) {
                e.forEach(function(value, index) {
                    if (value) {
                        selectedValue.push(value.key);
                    }
                });
            }
        searchCriteria = {...searchCriteria, [fieldName]:selectedValue}
        manChargeHdrDispatch(prevState => ({
            ...prevState,
            searchCriteria : {
                ...prevState.searchCriteria,
                ...searchCriteria,
            }
        }));
    };

    // Save new preference
    const openSaveNewConfirmModal = () => {
        manChargeHdrDispatch(prevState => ({ ...prevState, isShowSaveNewConfirmModal: true, }));
    };

    const openDeleteConfirmModal = () => {
        manChargeHdrDispatch(prevState => ({
            ...prevState,
            isShowDeleteConfirmModal: true,
        }))
    };

    const closeConfirmModal = () => {
        manChargeHdrDispatch(prevState => ({
            ...prevState,
            isShowSaveNewConfirmModal: false,
            isShowDeleteConfirmModal: false,
        }))
    };

    const onSearchClick = async() => {
        manChargeHdrDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        });       
    }

    const onAccTml = async(currentRows: ManualChargeHeaderEntity[]) => {
        await manualChargeHeaderRepo.accTml(currentRows)
    }

    const onResumeAccTml = async(currentRows: ManualChargeHeaderEntity[]) => {
        await manualChargeHeaderRepo.resumeAccTml(currentRows)
    }

    const onConfirm = async(currentRows: ManualChargeHeaderEntity[]) => {
        return await manualChargeHeaderRepo.confirm(currentRows)
    }

    const onUnConfirm = async(currentRows: ManualChargeHeaderEntity[]) => {
        await manualChargeHeaderRepo.unConfirm(currentRows)
    }

    const onDelete = async(currentRows: ManualChargeHeaderEntity[]) => {
        return await manualChargeHeaderRepo.deleteManCharge(currentRows.map(row => row.id).filter((id): id is number => id !== null)).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                manChargeHdrDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"deleteManualChargeFail": data.toString()}
                    };
                });
                return {"deleteManualChargeFail": data.toString()};
            } else {
                manChargeHdrDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"deleteManualChargeSuccess":"successful"},
                        isShowDetail: false,
                    };
                });
                return {"deleteManualChargeSuccess":"successful"};
            }
        }).catch(error => {
            manChargeHdrDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"deleteManualChargeFail": error.message}                        
                }
            });
            return {"deleteManualChargeFail": error.message};
        })
    }

    const onAdd = () => {
        manChargeHdrDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: !prevState.isShowDetail,
                currentManualChargeHeader: EMPTY_MANUAL_CHARGE_HEADER_ENTITY
            }
        });       
    }

    const onDetail = (currManChgHdr: ManualChargeHeaderEntity) => {
        manChargeHdrDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: !prevState.isShowDetail,
                currentManualChargeHeader: currManChgHdr,
            }
        });   
    }

    const onSelectedChargeHeader = (data: ManualChargeHeaderEntity) => {
        manChargeHdrDispatch(prevState => {
            const obj: ManualChargeHeaderMaintenanceModel = {
                ...prevState,
                currentManualChargeHeader: data,
            };
            return { ...obj }
        })
    }



    return {
        onSwitchSearchCriteriaMode,
        onCriteriaDirectoryDropdown: onCriteriaDirectoryDropdown,
        onCloseScreen: onCloseScreen,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        updateSelectedManualChargeHeaders: updateSelectedManualChargeHeaders,
        updateCheckboxRef: updateCheckboxRef,
        updateCheckboxValue: updateCheckboxValue,
        onSaveCheckboxValue: onSaveCheckboxValue,
        onResetCheckboxValue: onResetCheckboxValue,
        onRemoveSearchCriteria: onRemoveSearchCriteria,
        onRemoveAllSearchCriteria: onRemoveAllSearchCriteria,
        onEditSearchCriteria: onEditSearchCriteria,
        onSearch,
        loadDropdownOption: loadDropdownOption,
        onCoVslVoyChange: onCoVslVoyChange,
        onDateRangeChange: onDateRangeChange,
        onDropdownChange: onDropdownChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onCheckboxChange: onCheckboxChange,
        onMultipleCheckboxChange: onMultipleCheckboxChange,
        onInputTextChange: onInputTextChange,
        onGroupCheckboxChange: onGroupCheckboxChange,
        openSaveNewConfirmModal: openSaveNewConfirmModal,
        openDeleteConfirmModal: openDeleteConfirmModal,
        closeConfirmModal: closeConfirmModal,
        onSearchClick: onSearchClick,
        onAccTml: onAccTml,
        onResumeAccTml: onResumeAccTml,
        onConfirm: onConfirm,
        onDelete: onDelete,
        onUnConfirm: onUnConfirm,
        onAdd: onAdd,
        onDetail: onDetail,
        onSelectedChargeHeader: onSelectedChargeHeader,
        onSearchById: onSearchById,
    }
}