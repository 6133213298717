/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { convertDateToDateTimeForComponent, convertDateToLocal, convertDateToUTC } from "presentation/utils/timeUtil";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { CommonField, DateTimePicker, FieldType } from "veronica-ui-component/dist/component/core";

interface ComponentProps {
    label?:string,
    date: Date | null | string | undefined,
    onDateChange: (fieldName:string, date: any, fFullValue?: any, objName?: string) => void,
    onClose?: () => void,
    width?:string,
    errorMessage?: string,
    fieldName?: string,
    disabled?: boolean,
    optional?: boolean,
}

const DateTimePickerComponent:React.FC<ComponentProps> = (props) => {
    const componentRef = useRef<HTMLDivElement>(null);
    
    const [dateInternal, setDateInternal] = useState<any>(props.date?convertDateToLocal(moment(props.date)):null);

    const onDatesChange = useCallback((date: any) => {
        setDateInternal(date?moment(date):null);
        if (props.onDateChange && props.fieldName) {
            props.onDateChange(props.fieldName, (date?convertDateToUTC(moment(date))?.toDate():null));
        }
    }, [dateInternal]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const isNotClickingDateRangePicker = componentRef.current && !componentRef.current.contains(event.target as Node)
            if (isNotClickingDateRangePicker) {
                // Clicked outside the component
                if (props.onClose) {
                    props.onClose()
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return <div ref={componentRef}>
        {(!props.disabled) ?
        <DateTimePicker            
            label={props.label?props.label:''}
            width={props.width?props.width:'300px'}
            date={!props.date && !dateInternal && !dateInternal?.isValid() ? ""
                : (dateInternal && dateInternal?.isValid() ? dateInternal?.toDate()
                  : (props.date? convertDateToLocal(moment(props.date))?.toDate(): ""))}
            errorMessage={props.errorMessage}
            optional={props.optional}             
            onChange={(e) => {
                if (e) {
                    const start = e;
                    onDatesChange(start)
                }
            }} /> :
        <CommonField
            isReadOnly={true}
            readOnlyValue={dateInternal && dateInternal.isValid() ? 
                convertDateToDateTimeForComponent(dateInternal?.toDate()) :
                (props.date ?
                    convertDateToDateTimeForComponent(moment(props.date)) :
                    ""
                )}
            fieldLabel={props.label?props.label:''}
            fieldType={FieldType.TEXT}
            fieldKey={''}                
            requiredFieldList={[]}
            onFieldChange={() => {}} />
        }
    </div>

}

export default memo(DateTimePickerComponent);