export interface ManualChargeCntrSearchCriteria {    
    co?: string | null,
    vsl?: string | null,
    voy?: string | null,    
    chargeOnCompList?: string[] | null,
    billToCompList?: string[] | null,
    etdDateFrom?: Date | null,
    etdDateTo?: Date | null,
    etaDateFrom?: Date | null,
    etaDateTo?: Date | null,
    cntrList?: string[] | null,
    vesselName?: string | null

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_MANUAL_CHARGE_CNTR_SEARCH_CRITERIA : ManualChargeCntrSearchCriteria = {
    co: null,
    vsl: null,
    voy: null,
    chargeOnCompList: [],
    billToCompList: [],
    vesselName: null,
    etdDateFrom: null,
    etdDateTo: null,
    etaDateFrom: null,
    etaDateTo: null,
    cntrList: [],
}