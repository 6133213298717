import { EMPTY_MANUAL_CHARGE_DETAIL_ADJUSTMENT_REQUEST_ENTITY, ManualChargeDetailAdjustmentRequestEntity } from "domain/entity/ManualCharge/ManualChargeDetailAdjustmentRequestEntity";
import { EMPTY_MANUAL_CHARGE_DETAIL_CNTR_ENTITY, ManualChargeDetailCntrEntity } from "domain/entity/ManualCharge/ManualChargeDetailCntrEntity";
import { EMPTY_MANUAL_CHARGE_DETAIL_ENTITY, ManualChargeDetailEntity } from "domain/entity/ManualCharge/ManualChargeDetailEntity";
import { EMPTY_MANUAL_CHARGE_HEADER_ENTITY, ManualChargeHeaderEntity } from "domain/entity/ManualCharge/ManualChargeHeaderEntity";
import { EMPTY_MANUAL_CHARGE_CNTR_SEARCH_CRITERIA, ManualChargeCntrSearchCriteria } from "presentation/constant/ManualCharge/ManualChargeHeaderCntrSearchCriteria";
import { RefObject } from "react";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";
export interface ChargeDetailCntrViewChangeState {
    searchCriteria: ManualChargeCntrSearchCriteria,
    currentSelectedCntrs: ManualChargeDetailCntrEntity[],
    currentCntrNoStr: string,
    fileContentArray: string[],
    totalQty: number,
    isShowCntrInfoPanel: boolean,
    dynamicOptions: ManualChargeDtlCntrDropdownOptions,
    updateCntrRow: ManualChargeDetailCntrEntity,
    isInitialSearch: boolean
}
export interface ManualChargeDetailViewChangeState extends BaseViewChangeSate {
    currentSelectedData: ManualChargeDetailEntity,    
    editingHeader: ManualChargeHeaderEntity,   
    isShowHeaderInfo: boolean,
    isShowDetailInfo: boolean,
    updatedRows: ManualChargeDetailEntity[],
    isShowAttachmentModal: boolean,
    attachmentMsg: string | null,
    isAdjustmentRead: boolean,
}

export interface ManualChargeDetailDropdownOptions {
    billToCompDropdownOptions: DropdownProps[],
    operatingTmlDropdownOptions: DropdownProps[],
    serviceCodeDropdownOptions: DropdownProps[],
    chargeOnCompDropdownOptions: DropdownProps[],
    stateDropdownOptions: DropdownProps[],
    chargeTypeDropdownOptions: DropdownProps[],
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    taxCodeDropdownOptions: DropdownProps[],
    taxTariffTypeDropdownOptions: DropdownProps[],
    taxTariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    qtyUomDropdownOptions: DropdownProps[],
    articleStatementCodeDropdownOptions: DropdownProps[],
    taxPaymentRefDateDropdownOptions: DropdownProps[],
    chargeIndDropdownOptions: DropdownProps[],
    forwardCodeDropdownOptions: DropdownProps[],
    emptyIndDropdownOptions: DropdownProps[],
    partnerCodeDropdownOptions: DropdownProps[],
    tsStatusDropdownOptions: DropdownProps[],
    operationTypeDropdownOptions: DropdownProps[],
    shiftsDropdownOptions: DropdownProps[],
    allTariffCodeeDropdownOptions:DropdownProps[],
    reasonCodeDropdownOptions: DropdownProps[],
    cntrSizeDropdownOptions: DropdownProps[],
    cntrTypeDropdownOptions: DropdownProps[],
    cntrStatusDropdownOptions: DropdownProps[],
    modalityArrDropdownOptions: DropdownProps[],
    modalityDepDropdownOptions: DropdownProps[]
}

export interface ManualChargeDtlCntrDropdownOptions {
    billToCompDropdownOptions: DropdownProps[],    
    chargeOnCompDropdownOptions: DropdownProps[],    
    cntrSizeDropdownOptions: DropdownProps[],
    cntrTypeDropdownOptions: DropdownProps[],
    //cntrStatusDropdownOptions: DropdownProps[],
    modalityArrDropdownOptions: DropdownProps[],
    modalityDepDropdownOptions: DropdownProps[]
}

export interface ManualChargeDetailModel {
    isLoading: boolean,
    isShowRightPanel: boolean,
    isFilterSearchCriteria: boolean,
    isEditSearchCriteria: boolean,
    highlightedCheckboxKey?: string | null
    currentTableRef?: RefObject<any>,
    searchCounter: number,
    dynamicOptions: ManualChargeDetailDropdownOptions,
    isShowSaveNewConfirmModal: boolean,
    isShowDeleteConfirmModal: boolean,
    currentManualChargeHeader: ManualChargeHeaderEntity,
    manualChargeDetails: ManualChargeDetailEntity[],
    selectedManualChargeDetailRows: ManualChargeDetailEntity[],
    viewState: ManualChargeDetailViewChangeState,
    isShowCloseIcon: boolean,
    allFormState: {[key:string]:string},
    adjustmentEntity: ManualChargeDetailAdjustmentRequestEntity,
    isShowAdjustmentPanel?: boolean,
    initManChargeDtlCntrs:ManualChargeDetailCntrEntity[],
    manualChargeDetailCntrs:ManualChargeDetailCntrEntity[],
    manualChargeDetailCntrState: ChargeDetailCntrViewChangeState,
    isShowFindCntrPanel: boolean,
    cntrNoStr: string | null,
    dtlTotalQty: number | null
}

export const EMPTY_MANUAL_CHARGE_DETAIL_MODEL: ManualChargeDetailModel = {
    isLoading: false,
    isShowRightPanel: false,
    isFilterSearchCriteria: false,
    isEditSearchCriteria: false,
    isShowCloseIcon: true,
    searchCounter: 0,
    allFormState: {},
    adjustmentEntity: EMPTY_MANUAL_CHARGE_DETAIL_ADJUSTMENT_REQUEST_ENTITY,
    isShowAdjustmentPanel: false,
    dynamicOptions: {
        billToCompDropdownOptions: [],
        operatingTmlDropdownOptions: [],
        serviceCodeDropdownOptions: [],
        stateDropdownOptions: [],
        chargeTypeDropdownOptions: [],
        chargeOnCompDropdownOptions: [],
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        taxCodeDropdownOptions: [],
        taxTariffTypeDropdownOptions: [],
        taxTariffCodeDropdownOptions: {},
        qtyUomDropdownOptions: [],
        articleStatementCodeDropdownOptions: [],
        taxPaymentRefDateDropdownOptions: [],
        chargeIndDropdownOptions: [],
        forwardCodeDropdownOptions: [],
        emptyIndDropdownOptions: [],
        partnerCodeDropdownOptions: [],
        tsStatusDropdownOptions: [],
        operationTypeDropdownOptions: [],
        shiftsDropdownOptions:[],
        allTariffCodeeDropdownOptions:[],
        reasonCodeDropdownOptions: [],
        cntrSizeDropdownOptions: [],
        cntrTypeDropdownOptions: [],
        cntrStatusDropdownOptions: [],
        modalityArrDropdownOptions: [],
        modalityDepDropdownOptions: []
    },
    isShowSaveNewConfirmModal: false,
    isShowDeleteConfirmModal: false,
    currentManualChargeHeader: EMPTY_MANUAL_CHARGE_HEADER_ENTITY,
    manualChargeDetails: [],
    selectedManualChargeDetailRows: [],
    viewState: {
        isSliderOpen: false, // true - if stack form open
        isTabularDataActive: true, // false - If list view is active
        isAdd: false, // true - if use add new record
        isRead: false, // true - if user click to view record
        isAdjustmentRead: false,
        isEditable: false, // true - if user edit existing record
        isSaveClicked: false, // true - if user clicked on save button to save record
        currentSelectedData: EMPTY_MANUAL_CHARGE_DETAIL_ENTITY,
        editingHeader: EMPTY_MANUAL_CHARGE_HEADER_ENTITY,
        lastEditRowId: "",
        isSelected: false,
        allFormState: {},
        isShowHeaderInfo: false,
        isShowDetailInfo: false,
        updatedRows: [],
        isShowAttachmentModal: false,
        attachmentMsg: null,

        slider: {
            isAdd: false, // true - if use add new record
            isRead: false, // true - if user click to view record
            isEditable: false, // true - if user edit existing record
        },
    },
    manualChargeDetailCntrs: [],
    initManChargeDtlCntrs: [],
    manualChargeDetailCntrState: {
        searchCriteria: EMPTY_MANUAL_CHARGE_CNTR_SEARCH_CRITERIA,
        currentSelectedCntrs: [],
        currentCntrNoStr: "",
        fileContentArray: [],
        totalQty: 0,
        isShowCntrInfoPanel: false,
        dynamicOptions: {
            billToCompDropdownOptions: [],    
            chargeOnCompDropdownOptions: [],    
            cntrSizeDropdownOptions: [],
            cntrTypeDropdownOptions: [],
            //cntrStatusDropdownOptions: [],
            modalityArrDropdownOptions: [],
            modalityDepDropdownOptions: []
        },
        updateCntrRow: EMPTY_MANUAL_CHARGE_DETAIL_CNTR_ENTITY,
        isInitialSearch: true
    },
    isShowFindCntrPanel: false,
    cntrNoStr: null,
    dtlTotalQty: null
}