import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ManualChargeDetailProvider } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import ManualChargeDetailMaintenance from "presentation/view/section/ManualCharge/Detail/ManualChargeDetailMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const ManualChargeContMain = () => {
    return <ANAInfoWrapper permission={Permission.MANUAL_CHARGE_DETAIL}>
        <MessageBarWrapper>
            <ManualChargeDetailProvider>
                <GridStyles/>
                <ManualChargeDetailMaintenance/>
            </ManualChargeDetailProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default ManualChargeContMain;