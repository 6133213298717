import { ChargeDetailEntity } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity";
import { VesselVoyageMasterEntity } from "domain/entity/ChargeDetailMaintenance/VesselVoyageMasterEntity";
import { VesselVoyageMasterSearchCriteria } from "domain/entity/ChargeDetailMaintenance/VesselVoyageMasterSearchCriteria";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { VesselVoyageMasterRepository } from "./VesselVoyageMasterRepo";

export const VesselVoyageMasterRepoImpl = () : VesselVoyageMasterRepository => {
    const url = "/v1/vesselVoyageMasterSearch"
    const byCoVslVoyUrl = "/v1/vesselVoyageMasterSearchByCoVslVoy"

    const getVesselVoyageMaster = async(searchCriteria: VesselVoyageMasterSearchCriteria) : Promise<VesselVoyageMasterEntity[]> => {
        return axiosPostData(referenceAxiosInstance, `${url}`, searchCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const getVesselVoyageMasterByCoVslVoy = async(searchCriteria: VesselVoyageMasterSearchCriteria) : Promise<VesselVoyageMasterEntity> => {
        return axiosPostData(referenceAxiosInstance, `${byCoVslVoyUrl}`, searchCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const batchUpdate = async(chargeList:ChargeDetailEntity[]) : Promise<boolean> => {
        const newUrl = "/v1/chargeDetailBatchUpdate"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, chargeList).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const getVesselVoyageMasterSearchVesselName = async(searchCriteria: VesselVoyageMasterSearchCriteria) : Promise<VesselVoyageMasterEntity> => {
        const newUrl = "/v1/vesselVoyageMasterSearchVesselName"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, searchCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    return {
        getVesselVoyageMaster: getVesselVoyageMaster,
        getVesselVoyageMasterByCoVslVoy: getVesselVoyageMasterByCoVslVoy,
        batchUpdate: batchUpdate,
        getVesselVoyageMasterSearchVesselName: getVesselVoyageMasterSearchVesselName       
    }
}