import { ManualChargeCntrSearchCriteria } from "presentation/constant/ManualCharge/ManualChargeHeaderCntrSearchCriteria";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useManualChargeDetailCntrVM } from "presentation/hook/ManualCharge/useManualChargeDetailCntrVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { useCallback, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import ManualChargeCntrSearchForm from "./ManualChargeCntrSearchForm";

const ManualChargeCntrSearchPanel = () => {
    const manualChargeCntrVM = useManualChargeDetailCntrVM();
    const messageBarVM = useMessageBarVM();
    const [manualChargeDetailState] = useManualChargeDetailTracked();
    const { searchCriteria } = manualChargeDetailState.manualChargeDetailCntrState
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resetButtonClicked = useCallback(() => {
        manualChargeCntrVM.onRemoveAllSearchCriteria();
    }, [manualChargeCntrVM]);
    
    const searchButtonClicked = useCallback((criteria:ManualChargeCntrSearchCriteria) => {
        setIsLoading(true);
        manualChargeCntrVM.onSearch(criteria, manualChargeDetailState.viewState.currentSelectedData).then((data) => {
            if (data && data["searchManualChargeCntrFail"]) {
                messageBarVM.showError(data["searchManualChargeCntrFail"]?.toString());
            }
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
    }, [manualChargeCntrVM, manualChargeDetailState.viewState.currentSelectedData, messageBarVM])

    return (
        <div className='side-form-content-left-wrapper' style={{height:"73vh"}}>
            {/* <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}> */}
                    <div className='im-charge-data-search-right-panel-title'>SEARCH CRITERIA</div>
                    {/* <Sidebarheader>
                        <SidebarTitle>{'Search Criteria'}</SidebarTitle>

                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={manualChargeCntrVM.onShowPanel} />
                        </SidebarActionCross>
                    </Sidebarheader>                 */}
                {/* </div>
            </div> */}

            {isLoading && <Loader Indicator="Spinner" size="Medium"/>}

            <div className={'add-edit-form'} style={{ height:"57vh", overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        {/* Stack Form Start */}
                        <ManualChargeCntrSearchForm/>
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={WorkspaceConstant.Common.BUTTON_RESET} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_SEARCH} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked(searchCriteria)} />
            </SidebarActionBar>
        </div>
    );
}

export default ManualChargeCntrSearchPanel;
