import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { memo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { ManualChargeAdjustmentPanel } from "./Form/ManualChargeAdjustmentPanel";
import { ManualChargeDetailFormPanel } from "./Form/ManualChargeDetailFormPanel";
import ManualChargeDetailTablePanel from "./Table/ManualChargeDetailTablePanel";

const ManualChargeDetailContent:React.FC = () => {
        const [ manualChargeDetailState ] = useManualChargeDetailTracked();
        const { isShowDetailInfo } = manualChargeDetailState.viewState;
        const { isShowAdjustmentPanel } = manualChargeDetailState

    return <div className={`main-comp-wrapper${isShowDetailInfo || isShowAdjustmentPanel ? '' : ' im-hide-side-form-draggable'}`}>
            <SliderPanel
                isOpen={isShowDetailInfo || isShowAdjustmentPanel}
                leftSectionWidth={isShowAdjustmentPanel ? '70%' :'30%'}
                rightSectionWidth={isShowAdjustmentPanel ? '30%' : '70%'}
                draggable={true}
                leftChildren={<ManualChargeDetailTablePanel/>}
                rightChildren={isShowAdjustmentPanel ? <ManualChargeAdjustmentPanel/> :<ManualChargeDetailFormPanel/>} />
        </div>
}

export default memo(ManualChargeDetailContent);