import { statusDropdownOptions } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { manChgHdrRequiredFieldList, ManualChargeConstant } from "presentation/constant/ManualCharge/ManualChargeConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useManualChargeDetailCntrVM } from "presentation/hook/ManualCharge/useManualChargeDetailCntrVM";
//import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateTimePickerComponent from "presentation/view/components/DateTimePickerComponent";
import {  useEffect, useMemo } from "react";
import { CommonField, FieldType, HPHButton, IFieldValue, } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const ManualChargeCntrFormPanel = () => {    
    const [ manualChargeDetailState ] = useManualChargeDetailTracked();
    const manualChargeDetailCntrVM = useManualChargeDetailCntrVM();
    const {isShowCntrInfoPanel, dynamicOptions, updateCntrRow} = manualChargeDetailState.manualChargeDetailCntrState;

    const CNTR_INFO_CONST = ManualChargeConstant.CntrInfo;
   
    useEffect(() => {
        manualChargeDetailCntrVM.loadDropdownOption();        
    }, [isShowCntrInfoPanel, manualChargeDetailCntrVM]);

    const handleCancelClick = () => {
        manualChargeDetailCntrVM.onCancelCntrInfo();
    }

    const handleUpdateClick = () => {
        if(updateCntrRow){
           manualChargeDetailCntrVM.onUpdateCntrInfo(updateCntrRow);
        }
    }
    
    const memoCntrNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={updateCntrRow?.cntrNo??''}
                fieldValue={updateCntrRow?.cntrNo}
                fieldLabel={CNTR_INFO_CONST.CNTR_NO}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'cntrNo'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [updateCntrRow?.cntrNo, CNTR_INFO_CONST.CNTR_NO])
    
    const memoCntrSize = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                //readOnlyValue={currentManChgHdr?.operatingTml || ''}
                fieldValue={updateCntrRow?.cntrSize??''}
                fieldLabel={CNTR_INFO_CONST.CNTR_SIZE}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrSize'}
                maxLength={60}
                options={dynamicOptions.cntrSizeDropdownOptions}
                requiredFieldList={[]} 
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailCntrVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [CNTR_INFO_CONST.CNTR_SIZE, updateCntrRow?.cntrSize, dynamicOptions.cntrSizeDropdownOptions, manualChargeDetailCntrVM])

    const memoCntrType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                fieldValue={updateCntrRow?.cntrType??''}
                fieldLabel={CNTR_INFO_CONST.CNTR_TYPE}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrType'}
                maxLength={60}
                options={dynamicOptions.cntrTypeDropdownOptions}
                requiredFieldList={[]}                 
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailCntrVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [CNTR_INFO_CONST.CNTR_TYPE, updateCntrRow?.cntrType, dynamicOptions.cntrTypeDropdownOptions, manualChargeDetailCntrVM])
       
    const memoCntrStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}                
                fieldValue={updateCntrRow?.cntrStatus??''}
                fieldLabel={CNTR_INFO_CONST.CNTR_STATUS}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrStatus'}
                maxLength={60}
                options={statusDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailCntrVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [updateCntrRow?.cntrStatus, CNTR_INFO_CONST.CNTR_STATUS, manualChargeDetailCntrVM])

    const memoOverLength = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}                
                fieldValue={updateCntrRow?.overLength??''}
                fieldLabel={CNTR_INFO_CONST.OVER_LENGTH}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'overLength'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailCntrVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [updateCntrRow?.overLength, CNTR_INFO_CONST.OVER_LENGTH, manualChargeDetailCntrVM])
    
    const memoOverHeight = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                //readOnlyValue={currentManChgHdr?.poNo || ''}
                fieldValue={updateCntrRow?.overHeight??''}
                fieldLabel={CNTR_INFO_CONST.OVER_HEIGTH}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'overHeight'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailCntrVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [updateCntrRow?.overHeight, CNTR_INFO_CONST.OVER_HEIGTH, manualChargeDetailCntrVM])

    const memoOverWidth = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}              
                fieldValue={updateCntrRow?.overWidth??''}
                fieldLabel={CNTR_INFO_CONST.OVER_WIDTH}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'overWidth'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailCntrVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [updateCntrRow?.overWidth, CNTR_INFO_CONST.OVER_WIDTH, manualChargeDetailCntrVM])

    const memoImcoCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}                
                fieldValue={updateCntrRow?.imcoCode??''}
                fieldLabel={CNTR_INFO_CONST.IMCO_CODE}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'imcoCode'}
                maxLength={60}
                requiredFieldList={manChgHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailCntrVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [updateCntrRow?.imcoCode, CNTR_INFO_CONST.IMCO_CODE, manualChargeDetailCntrVM])

    const memoModalityArr = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}                
                fieldValue={updateCntrRow?.modalityOfArrival??''}
                fieldLabel={CNTR_INFO_CONST.MODALITY_ARR}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'modalityOfArrival'}
                maxLength={60}
                options={dynamicOptions.modalityArrDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailCntrVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [updateCntrRow?.modalityOfArrival, CNTR_INFO_CONST.MODALITY_ARR, dynamicOptions.modalityArrDropdownOptions, manualChargeDetailCntrVM])

    const memoModalityDep = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}                
                fieldValue={updateCntrRow?.modalityOfDeparture??''}
                fieldLabel={CNTR_INFO_CONST.MODALITY_DEP}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'modalityOfDeparture'}
                maxLength={60}
                options={dynamicOptions.modalityDepDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailCntrVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [updateCntrRow?.modalityOfDeparture, CNTR_INFO_CONST.MODALITY_DEP, dynamicOptions.modalityDepDropdownOptions, manualChargeDetailCntrVM])

    const memoInDateTime = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <DateTimePickerComponent
                    disabled={false}
                    label={CNTR_INFO_CONST.IN_DATE_TIME}
                    width="150px"
                    date={updateCntrRow?.inTmlDatetime??''}
                    fieldName="inTmlDatetime" 
                    optional={true}       
                    errorMessage={''}
                    onDateChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any, objName?: any) => 
                        manualChargeDetailCntrVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue, FieldType.DATE_TIME)}/>
        </div>
    , [CNTR_INFO_CONST.IN_DATE_TIME, updateCntrRow?.inTmlDatetime, manualChargeDetailCntrVM])

    const memoOutDateTime = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <DateTimePickerComponent
                    disabled={false}
                    label={CNTR_INFO_CONST?.OUT_DATE_TIME}
                    width="150px"
                    date={updateCntrRow?.outTmlDatetime??''}
                    fieldName="outTmlDatetime" 
                    optional={true}       
                    errorMessage={''}
                    onDateChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any, objName?: any) => 
                        manualChargeDetailCntrVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue, FieldType.DATE_TIME)}/>
        </div>
    , [CNTR_INFO_CONST?.OUT_DATE_TIME, updateCntrRow?.outTmlDatetime, manualChargeDetailCntrVM])
    
    const memoChargeQty = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}                
                fieldValue={updateCntrRow?.qty?.toString()??''}
                fieldLabel={CNTR_INFO_CONST.CHARGE_QTY}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'qty'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailCntrVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [CNTR_INFO_CONST.CHARGE_QTY, updateCntrRow?.qty, manualChargeDetailCntrVM])

    const memoSpecialInd = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}                
                fieldValue={updateCntrRow?.specialInd??''}
                fieldLabel={CNTR_INFO_CONST.SPECIAL_IND}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'SpecialInd'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [updateCntrRow?.specialInd, CNTR_INFO_CONST.SPECIAL_IND])

    const memoSpHandlingInd = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true} 
                readOnlyValue={updateCntrRow?.specialHandlingInd??''}          
                fieldValue={updateCntrRow?.specialHandlingInd}
                fieldLabel={CNTR_INFO_CONST.SP_HANDLING_IND}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'spHandlingInd'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [updateCntrRow?.specialHandlingInd, CNTR_INFO_CONST.SP_HANDLING_IND])

    const memoSpecialHandlingCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={updateCntrRow?.spCodeList??''}
                fieldValue={updateCntrRow?.spCodeList}
                fieldLabel={CNTR_INFO_CONST.SPECIAL_HANDLING_CODE}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'specialHandlingCode'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [updateCntrRow?.spCodeList, CNTR_INFO_CONST.SPECIAL_HANDLING_CODE])
    
    const memoSpecialHandlingIndD1 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={updateCntrRow?.legSpHandlingInd??''}
                fieldValue={updateCntrRow?.legSpHandlingInd}
                fieldLabel={CNTR_INFO_CONST.SPECIAL_HANDLING_IND_D1}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'specialHandlingIndD1'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [updateCntrRow?.legSpHandlingInd, CNTR_INFO_CONST.SPECIAL_HANDLING_IND_D1])

    const memoSpecialHandlingCodeD1 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={updateCntrRow?.legSpHandlingCode??''}
                fieldValue={updateCntrRow?.legSpHandlingCode}
                fieldLabel={CNTR_INFO_CONST.SPECIAL_HANDLING_CODE_D1}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'specialHandlingCodeD1'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [updateCntrRow?.legSpHandlingCode, CNTR_INFO_CONST.SPECIAL_HANDLING_CODE_D1])

    return <>
        <div className='side-form-content-left-wrapper' style={{height:"73vh"}}>          
            
            <div className={'add-edit-form'} style={{ height:"57vh", overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoCntrNo}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoCntrSize}
                            {memoCntrType}
                            {memoCntrStatus}                           
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoOverLength}
                            {memoOverHeight}
                            {memoOverWidth}                           
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoImcoCode}
                            {memoModalityArr}
                            {memoModalityDep}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoInDateTime}
                            {memoOutDateTime}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoChargeQty}
                            {memoSpecialInd}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoSpHandlingInd}
                            {memoSpecialHandlingCode}                            
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoSpecialHandlingIndD1}
                            {memoSpecialHandlingCodeD1}                            
                        </CriteriaItemContainer>                        
                    </div>                                  
                </div>
            </div>
            {/* <StyledAction className="tm-animated-wrapper">                     
                <HPHButton label={WorkspaceConstant.Common.BUTTON_CLOSE} disabled={!isShowCntrInfoPanel} size={'Small'} theme={'Secondary'} onClick={()=>{}} />                
                <HPHButton label={WorkspaceConstant.Common.BUTTON_UPDATE} disabled={!isShowCntrInfoPanel} size={'Small'} theme={'Primary'} onClick={()=>{}} />
            </StyledAction>  */}
            <SidebarActionBar>
                <HPHButton label={WorkspaceConstant.Common.BUTTON_CLOSE} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_UPDATE} size={'Small'} theme={'Primary'} onClick={handleUpdateClick} />
            </SidebarActionBar> 
        </div>
    </>
}