import { ChargeConstantCollection } from 'constants/charge/ChargeConstantCollection';
import { DocumentConst } from 'constants/document/DocumentConst';
import moment from 'moment';
import * as yup from 'yup';
import { MessageConstant, formatMessage } from '../MessageConstant';
import { ManualChargeConstant } from './ManualChargeConstant';

const userMessage = MessageConstant.common;
const HDR_CONST = ManualChargeConstant.Header;
const DTL_CONST = ManualChargeConstant.Detail;
const vatPaymentTerms = Object.values(DocumentConst.vatPaymentRefDateType);
const uoms = Object.values(ChargeConstantCollection.uom);

function ValidateDateRange(fromKey:string,toKey:string, context: yup.TestContext<yup.AnyObject>) {
  const fromDate = context.parent[fromKey];
  const toDate = context.parent[toKey];
  const varFromDate = moment(fromDate);
  const varToDate = moment(toDate);

  let error: string = '';
  if (varFromDate > varToDate) {
    error = formatMessage(userMessage.TO_GRATER_FROM, 
      {"{fromDate}":DTL_CONST.CHG_FROM_DATE,
       "{toDate}":DTL_CONST.CHG_TO_DATE})
  }

  if (error === '') {
    return true;
  } else {
    return false;
  }
}

export const commonManChgDtlValidation: { [x: string]: any; } = {
  chargeOnCompany: yup.string().required("Missing"),
  //fromDate: yup.date().required("Missing"),
  //toDate: yup.date().required("Missing"),
  validateRangeDate: yup.string().test("date-range-check", 
    formatMessage(MessageConstant.common.TO_GRATER_FROM, 
      {"{fromDate}":DTL_CONST.CHG_FROM_DATE,
       "{toDate}":DTL_CONST.CHG_TO_DATE
      }), 
    function(){
      return ValidateDateRange('fromDate','toDate',this);
    }),
    tariffType: yup.string().required("Missing"),
    tariffCode: yup.string().required("Missing"),
    chargeQty: yup.number().required("Missing").min(1).max(9999),
    vatPercentage: yup.number().required("Missing").min(0).max(100),
    vatOneOffTaxCode: yup.string().required("Missing"),
    individualChargeQtyUom: yup.string().required("Missing").oneOf(uoms),
    vatTarType: yup.string().required("Missing"),
    vatTarCode: yup.string().required("Missing"),
    vatArticleStatementCode: yup.string().required("Missing"),
    vatDays: yup.number().required("Missing").positive("Must be a positive number"),
    vatDaysCredit: yup.number().required("Missing").positive("Must be a positive number"),
    vatDaysDebit: yup.number().required("Missing").positive("Must be a positive number"),
    paymentReferenceDate: yup.string().required("Missing").oneOf(vatPaymentTerms),
    paymentReferenceDateCredit: yup.string().required("Missing").oneOf(vatPaymentTerms),
    paymentReferenceDateDebit: yup.string().required("Missing").oneOf(vatPaymentTerms),

    additionalChargeQty: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable().positive("Must be a positive number"),
    rate: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable().positive("Must be a positive number"),
    chargeSharePercentage: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable().min(0).max(100),
    chargePercentage: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable().min(0).max(100),

};

export const commonManChgHdrValidation: { [x: string]: any; } = {
    billToCompany: yup.string().required("Missing"),
    opsDate: yup.date().required("Missing"),
    operatingTml: yup.string().required("Missing"),
    chargeType: yup.string().required("Missing"),
    arrDepRangeDate: yup.string().test("date-range-check", 
      formatMessage(MessageConstant.common.TO_GRATER_FROM, 
        {"{fromDate}":HDR_CONST.ARR_DATETIME,
         "{toDate}":HDR_CONST.DEP_DATETIME
        }), 
      function(){
        return ValidateDateRange('eta','etd',this);
    }),
    loa: yup.number().transform((value) => (isNaN(value) ? -1 : value)).nullable().min(0).max(999),
};

export const createManChgDtlValidation: { [x: string]: any; } = {
  ...commonManChgDtlValidation,
};

export const updateManChgDtlValidation: { [x: string]: any; } = {
  ...commonManChgDtlValidation,
};

export const createManChgHdrValidation: { [x: string]: any; } = {
  ...commonManChgHdrValidation,
};

export const updateManChgHdrValidation: { [x: string]: any; } = {
  ...commonManChgHdrValidation,
};

export const createManChgHdrValidationSchema = yup.object().shape(createManChgHdrValidation);
export const updateManChgHdrValidationSchema = yup.object().shape(updateManChgHdrValidation);

export const createManChgDtlValidationSchema = yup.object().shape(createManChgDtlValidation);
export const updateManChgDtlValidationSchema = yup.object().shape(updateManChgDtlValidation);