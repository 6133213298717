import { EMPTY_MANUAL_CHARGE_DETAIL_MODEL } from 'presentation/model/ManualChargeMaintenance/ManualChargeDetailModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: ManualChargeDetailProvider,
    useTracked: useManualChargeDetailTracked
} = createContainer(() => useState(EMPTY_MANUAL_CHARGE_DETAIL_MODEL), {concurrentMode: true});
export { ManualChargeDetailProvider, useManualChargeDetailTracked };

