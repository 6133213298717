import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { ManualChargeConstant } from "presentation/constant/ManualCharge/ManualChargeConstant";
import { useManualChargeDetailCntrVM } from "presentation/hook/ManualCharge/useManualChargeDetailCntrVM";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { memo, useMemo } from "react";
import { HPHInputMask, HPHInputTextarea, InputDropdown, InputField } from "veronica-ui-component/dist/component/core";

const ManualChargeCntrSearchForm = () => {   
    const [ manualChargeDetailState ] = useManualChargeDetailTracked();
    const manualChargeCntrVM = useManualChargeDetailCntrVM();
    const cntrSearchCriteria = manualChargeDetailState.manualChargeDetailCntrState.searchCriteria;

    const memoCoVslVoy = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHInputMask
                label={ManualChargeConstant.Header.CO_VSL_VOY}                
                width={'240px'}
                value={{
                    co: cntrSearchCriteria.co ?? '',
                    vsl: cntrSearchCriteria.vsl ?? '',
                    voy: cntrSearchCriteria.voy ?? ''
                }}
                fieldConfiguration={{
                    co: { maxLength: 60, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    vsl: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    voy: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                }}
                onDataChange={(e: any) => manualChargeCntrVM.onCoVslVoyChange(e, {
                    co: 'co',
                    vsl: 'vsl',
                    voy: 'voy'
                })}/>
        </div>
    , [cntrSearchCriteria.co, cntrSearchCriteria.vsl, cntrSearchCriteria.voy, manualChargeCntrVM])    
    
    const memoVslName = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='240px'
                maxLength={30}
                label={ChargeConstant.ChargeHeader.VESSEL_NAME}
                type="text"
                value={cntrSearchCriteria.vesselName || ''}
                onChange={(e) => manualChargeCntrVM.onInputTextChange(e, 'vesselName')}/>
        </div>
    , [cntrSearchCriteria.vesselName, manualChargeCntrVM])

    const memoEtdFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={ChargeConstant.Charge.ETD_RANGE}
                width='240px'
                fieldNames={{startField:"etdDateFrom", endField:"etdDateTo"}}
                dateRange={{startDate:cntrSearchCriteria.etdDateFrom, endDate:cntrSearchCriteria.etdDateTo}}
                onDatesChange={manualChargeCntrVM.onDateRangeChange}
            />                
        </div>
    , [cntrSearchCriteria.etdDateFrom, cntrSearchCriteria.etdDateTo, manualChargeCntrVM.onDateRangeChange])

    const memoEtaFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={ChargeConstant.ChargeHeader.ETA_RANGE}
                width='240px'
                fieldNames={{startField:"etaDateFrom", endField:"etaDateTo"}}
                dateRange={{startDate:cntrSearchCriteria.etaDateFrom, endDate:cntrSearchCriteria.etaDateTo}}
                onDatesChange={manualChargeCntrVM.onDateRangeChange}
            />                
        </div>
    , [cntrSearchCriteria.etaDateFrom, cntrSearchCriteria.etaDateTo, manualChargeCntrVM.onDateRangeChange])

    const memoCntrNo = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHInputTextarea
                label={ChargeConstant.Charge.CNTR_NO}
                width="240px"
                rows={3}               
                resizable={false}
                helpIcon={true}
                toolTipText={"Use comma ( , ) as delimiter"}
                value={cntrSearchCriteria.cntrList?.join(",")}
                onChange={(e) => {manualChargeCntrVM.onTextAreaChange(e, "cntrList", true)}} />
        </div>
    , [cntrSearchCriteria.cntrList, manualChargeCntrVM])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown
                label={ManualChargeConstant.Header.BILL_TO_COMPANY}
                inputType="freeText"
                width="240px"
                mode={'multiple'}
                sort={false}
                value={cntrSearchCriteria.billToCompList?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => manualChargeCntrVM.onMultipleDropdownChange(e, 'billToCompList')}
                options={manualChargeDetailState.dynamicOptions.billToCompDropdownOptions}/>
        </div>
    , [cntrSearchCriteria.billToCompList, manualChargeCntrVM, manualChargeDetailState.dynamicOptions.billToCompDropdownOptions])

    const memoChargeOnCompany = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown
                label={ChargeConstant.Charge.CHARGE_ON_COMPANY}
                inputType="freeText"
                width="240px"
                mode={'multiple'}
                sort={false}
                value={cntrSearchCriteria.chargeOnCompList?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => manualChargeCntrVM.onMultipleDropdownChange(e, 'chargeOnCompList')}
                options={manualChargeDetailState.dynamicOptions.chargeOnCompDropdownOptions}/>
        </div>
    , [cntrSearchCriteria.chargeOnCompList, manualChargeCntrVM, manualChargeDetailState.dynamicOptions.chargeOnCompDropdownOptions])

    return <>        
        
        <CriteriaItemContainer>
           {memoCoVslVoy}
        </CriteriaItemContainer> 

        <CriteriaItemContainer>
           {memoEtdFromTo}
        </CriteriaItemContainer>          

        <CriteriaItemContainer>
           {memoEtaFromTo}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
           {memoBillToCompany}
         </CriteriaItemContainer>

        
        <CriteriaItemContainer>
           {memoChargeOnCompany}
        </CriteriaItemContainer>
        
        <CriteriaItemContainer>
           {memoCntrNo}
        </CriteriaItemContainer>
                
        <CriteriaItemContainer>
           {memoVslName}
        </CriteriaItemContainer>
    </>;
}
export default memo(ManualChargeCntrSearchForm);