export interface ManualChargeHeaderSearchCriteria {    
    co?: string | null,
    vsl?: string | null,
    voy?: string | null,    
    refNo?: string | null,
    stateList?: string[] | null,
    chargeTypeList?: string[] | null,
    billToCompList?: string[] | null,
    invCreditNoteNo?: string | null,
    opsDateFrom?: Date | null,
    opsDateTo?: Date | null,
    createdDateFrom?: Date | null,
    createdDateTo?: Date | null,
    cntrNo?: string | null,
    id?: number | null

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA : ManualChargeHeaderSearchCriteria = {
    co: null,
    vsl: null,
    voy: null,    
    refNo: null,
    stateList: [],
    chargeTypeList: [],
    billToCompList: [],
    invCreditNoteNo: null,
    opsDateFrom: null,
    opsDateTo: null,
    createdDateFrom: null,
    createdDateTo: null,
    cntrNo: null,
    id: null
}