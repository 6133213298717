import { ManualChargeConstant } from "presentation/constant/ManualCharge/ManualChargeConstant";
import { useManualChargeDetailCntrVM } from "presentation/hook/ManualCharge/useManualChargeDetailCntrVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { memo, useEffect, useState } from "react";
import { DialogModal, IconButton, Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import ManualChargeCntrSearchPanel from "./Form/ManualChargeCntrSearchPanel";
import ManualChargeCntrContent from "./ManualChargeCntrContent";

const ManualChargeCntrComp:React.FC = () => {
    const [ manualChargeDetailState ] = useManualChargeDetailTracked();
    const manualChargeDetailCntrVM = useManualChargeDetailCntrVM();
    const { isShowFindCntrPanel } = manualChargeDetailState;
    const {isInitialSearch} = manualChargeDetailState.manualChargeDetailCntrState;
    const [isLoading, setIsLoading] = useState(false);
    const messageBarVM = useMessageBarVM();

    useEffect(() => {
        if (!isInitialSearch) return;

        setIsLoading(true);
        manualChargeDetailCntrVM.initSearch(manualChargeDetailState.viewState.currentSelectedData, manualChargeDetailState.cntrNoStr, manualChargeDetailState.initManChargeDtlCntrs).then((data) => {
            if (data && data["initManualChargeCntrFail"]) {
                messageBarVM.showError(data["initManualChargeCntrFail"]?.toString());
            }           
            setIsLoading(false);
        })
    }, [isInitialSearch, manualChargeDetailCntrVM, manualChargeDetailState.cntrNoStr, manualChargeDetailState.initManChargeDtlCntrs, manualChargeDetailState.viewState.currentSelectedData, messageBarVM])

    const handleCloseDialog = () => {        
        manualChargeDetailCntrVM.closeCntrCompDialog();
    }

    if (isLoading) return <Loader Indicator="Spinner" size="Medium" />;

    return <DialogModal style={{  minWidth:'62vw', minHeight:'85vh' }}
        appendTo='self'
        showButton={false}
        visible={isShowFindCntrPanel}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">{ManualChargeConstant.Detail.CNTR_DTL}</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={handleCloseDialog} tooltipDisable={true}/>
            </div>
        }
        dialogContent={
            <div className={"main-comp-wrapper"} style={{width:"60vw", height:"73vh"}}>
            <SliderPanel
                isOpen={isShowFindCntrPanel}
                leftSectionWidth={'30%'}
                rightSectionWidth={'68%'}
                draggable={true}
                leftChildren={<ManualChargeCntrSearchPanel/>}
                rightChildren={<ManualChargeCntrContent/>} />
        </div>
        }        
        className="im-charge-data-search-confirm-modal"
    />
}

export default memo(ManualChargeCntrComp);