import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { memo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import {ManualChargeCntrTablePanel} from "./Table/ManualChargeCntrTablePanel";
import {ManualChargeCntrFormPanel} from "./Form/ManualChargeCntrFormPanel";

const ManualChargeCntrContent:React.FC = () => {
        const [ manualChargeDetailState ] = useManualChargeDetailTracked();
        //const { isShowDetailInfo } = manualChargeDetailState.viewState;
        //const { isShowFindCntrPanel } = manualChargeDetailState
        const { isShowCntrInfoPanel } = manualChargeDetailState.manualChargeDetailCntrState

        return <div className={`main-comp-wrapper${isShowCntrInfoPanel ? '' : ' im-hide-side-form-draggable'}`}>
            <SliderPanel
                isOpen={isShowCntrInfoPanel}
                leftSectionWidth={isShowCntrInfoPanel ? '70%' :'30%'}
                rightSectionWidth={isShowCntrInfoPanel ? '30%' : '70%'}
                draggable={true}
                leftChildren={<ManualChargeCntrTablePanel/>}
                rightChildren={<ManualChargeCntrFormPanel/>} />
        </div>
}

export default memo(ManualChargeCntrContent);