export interface ManualChargeHeaderEnabledSearchCriteria {   
    all: boolean,
    coVslVoy: boolean,
    refNo: boolean,
    stateList: boolean,
    chargeTypeList: boolean,
    billToCompList: boolean,
    invCreditNoteNo: boolean,
    opsDate: boolean,
    createdDate: boolean,
    cntrNo: boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA : ManualChargeHeaderEnabledSearchCriteria = {
    all: true,
    coVslVoy: true,
    refNo: true,
    stateList: true,
    chargeTypeList: true,
    billToCompList: true,
    invCreditNoteNo: true,
    opsDate: true,
    createdDate: true,
    cntrNo: true
}